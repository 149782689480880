<template>
  <h3 class="my-4 text-center">
    This page will display all hotel offers (such as discounted extended stays, spa and
    massage), and local offers are provided by the local businesses in the same hotel area.
  </h3>
</template>

<script>
export default {
  name: 'UnderConstruction',
}
</script>

<style scoped></style>
